import { API_NAME, GLUCOSE_KEYS } from "../../constants";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { v4 } from "uuid";
import _, { cloneDeep } from "lodash";

const initState = {
  scenes: [],
  scene: null,
  editingScene: null,
  editingSceneLoading: false,
};

export const scenesGet = createAsyncThunk("scenes/get", async (_, thunkApi) => {
  let res = null;
  try {
    res = await API.get(API_NAME, "/scenes");
  } catch (err) {
    console.error(err);
  }
  return res.body;
});

export const sceneGet = createAsyncThunk("scene/get", async (id, thunkApi) => {
  let res = null;
  try {
    res = await API.get(API_NAME, `/scenes/${id}`);
    console.log(res);
  } catch (err) {
    console.error(err);
  }
  return res.body;
});

export const sceneEditingGet = createAsyncThunk(
  "scene/editing/get",
  async (id, thunkApi) => {
    let res = null;
    try {
      res = await API.get(API_NAME, `/scenes/${id}`);
    } catch (err) {
      console.error(err);
    }
    return res.body;
  }
);

export const sceneEditingNullOut = createAction("scene/edit/nullOut");
export const sceneNullOut = createAction("scene/nullOut");

export const sceneCreate = createAsyncThunk(
  "scene/create",
  async (payload, thunkApi) => {
    // make gcLevels object
    // add default state Initial
    const gcLevels = GLUCOSE_KEYS.reduce((obj, key) => {
      obj[key] = [
        // Add default details
        {
          ID: "",
          "GC Level": key,
          "User Events": "OnInit",
          TimeIncr: "",
          NextState: "",
          Dialog: `#host Hello, your glucose is ${key.toLowerCase()}.`,
          Notes: "",
          active: true,
        },
        {
          ID: "",
          "GC Level": key,
          "User Events": "OnReprompt",
          TimeIncr: "0",
          NextState: "",
          Dialog: `#host Hello, your glucose is ${key.toLowerCase()}.`,
          Notes: "",
          active: true,
        },
      ];
      return obj;
    }, {});

    const state = {
      "State Name": "Initial",
      "State MetaData": "",
      "State Points": "0",
      gcLevels,
    };

    if (typeof payload === "string") payload = JSON.parse(payload);
    payload.states.push(state);

    let res = null;
    try {
      res = await API.post(API_NAME, "/scenes", {
        body: {
          info: payload,
          id: v4(),
        },
      });
    } catch (err) {
      console.error(err);
    }

    return res.body;
  }
);
export const scenePut = createAsyncThunk(
  "scene/put",
  async ({ id, payload: info }, thunkApi) => {
    let res = null;
    try {
      res = await API.put(API_NAME, "/scenes", {
        body: {
          id,
          info,
        },
      });
      thunkApi.dispatch(scenesGet());
    } catch (err) {
      console.error(err);
    }
    return res.body;
  }
);

export const sceneDelete = createAsyncThunk(
  "scene/delete",
  async (id, thunkApi) => {
    let res = null;
    // TODO - get scene info and set active=false
    try {
      // TODO - don't use delete, just use patch / put to flag this as inactive
      res = await API.del(API_NAME, `/scenes/${id}`);
    } catch (err) {
      console.error(err);
    }
    return res.body;
  }
);

// Add State by state name
export const sceneCreateState = createAsyncThunk(
  "scene/create/new/state",
  async (stateName, thunkApi) => {
    // Save new glucose state for entire scene object.

    // make gcLevels object
    const gcLevels = GLUCOSE_KEYS.reduce((obj, key) => {
      obj[key] = [
        // Add default details
        {
          ID: "",
          "GC Level": key,
          "User Events": "OnInit",
          TimeIncr: "",
          NextState: "",
          Dialog: `#host Hello your glucose is ${key.toLowerCase()}.`,
          Notes: "",
          active: true,
        },
        {
          ID: "",
          "GC Level": key,
          "User Events": "OnReprompt",
          TimeIncr: "0",
          NextState: "",
          Dialog: `#host Hello your glucose is ${key.toLowerCase()}.`,
          Notes: "",
          active: true,
        },
      ];
      return obj;
    }, {});

    const state = {
      "State Name": stateName,
      "State MetaData": "",
      "State Points": "0",
      gcLevels,
    };
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };
    info.states.push(state);

    // Add state to scene states.
    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

// Save dialog for glucose state
export const sceneSaveMetadata = createAsyncThunk(
  "scene/save/metadata",
  async (params, thunkApi) => {
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };
    info["Scene Description"] = params["Scene Description"];
    info["Scene Difficulty"] = params["Scene Difficulty"];
    info["Scene Name"] = params["Scene Name"];
    info["Scene Points"] = params["Scene Points"];
    info["Scene Starting BG"] = params["Scene Starting BG"];
    info["Scene Starting Time"] = params["Scene Starting Time"];
    info["Scene Title"] = params["Scene Title"];
    info["Scene Type"] = params["Scene Type"];
    info["active"] = params["active"];
    info["published"] = params["published"];

    // Add state to scene states.
    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

// Save dialog for glucose state
export const sceneSaveDialog = createAsyncThunk(
  "scene/save/dialog",
  async ({ stateName, gcLevel, action, dialog }, thunkApi) => {
    // Save new glucose state for entire scene object.
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };

    // Update Dialog of object keyed on states / gcLevel / User Events (action)
    // Build lodash key
    // key will look like "info.states[2].gcLevels.gcLevel[3].Dialog"
    const stateIndex = info.states.findIndex(
      (n) => n["State Name"] === stateName
    );
    let key = `[${stateIndex}].gcLevels.${gcLevel}`;
    // get gc levels array
    const gcLevels = _.get(info.states, key);
    const gcLevelStateIndex = gcLevels.findIndex(
      (n) => n["User Events"] === action
    );
    key += `.[${gcLevelStateIndex}].Dialog`;

    _.set(info.states, key, dialog);

    // Add state to scene states.
    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

export const sceneAddUserAction = createAsyncThunk(
  "scene/add/user/action",
  async ({ stateName, gcLevel, action }, thunkApi) => {
    // Save new glucose state for entire scene object.
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };

    // Build lodash key
    const stateIndex = info.states.findIndex(
      (n) => n["State Name"] === stateName
    );
    let key = `[${stateIndex}].gcLevels.${gcLevel}`;
    const gcLevelUserEventsArray = _.get(info.states, key);

    const newUserEvent = {
      ID: "Scenerio 100_0",
      "GC Level": gcLevel,
      "User Events": action,
      TimeIncr: "0",
      NextState: "",
      Dialog: "#host Hello.",
      Notes: "",
    };

    gcLevelUserEventsArray.push(newUserEvent);

    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

// Delete user action
export const sceneDeleteUserAction = createAsyncThunk(
  "scene/delete/user/action",
  async ({ stateName, gcLevel, action }, thunkApi) => {
    // Save new glucose state for entire scene object.
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };

    // Build lodash key
    const stateIndex = info.states.findIndex(
      (n) => n["State Name"] === stateName
    );
    let key = `[${stateIndex}].gcLevels.${gcLevel}`;
    const gcLevelUserEventsArray = _.get(info.states, key);

    const index = gcLevelUserEventsArray.findIndex(
      (n) => n["User Events"] === action
    );
    if (index > -1) {
      gcLevelUserEventsArray.splice(index, 1);
      // gcLevelUserEventsArray.filter((n) => n["User Events"] !== action);
      thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
    } else {
      console.error("unable to find user event to delete");
    }
  }
);

// Delete State by state name
export const sceneDeleteState = createAsyncThunk(
  "scene/delete/state",
  async (stateName, thunkApi) => {
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };
    info.states.forEach((value, index, arr) => {
      if (value["State Name"] === stateName) {
        arr.splice(index, 1);
      }
    });

    // Add state to scene states.
    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

// Update Next State Value of gcLevel entity
export const sceneUpdateGCLevelNextState = createAsyncThunk(
  "scene/update/gcLevel/nextState",
  async ({ stateName, gcLevel, action, nextState }, thunkApi) => {
    // Save new glucose state for entire scene object.
    const _state = cloneDeep(thunkApi.getState());
    const scene = _state.scenes.editingScene;
    const info = { ...scene.info };

    // Update Dialog of object keyed on states / gcLevel / User Events (action)
    // Build lodash key
    // key will look like "info.states[2].gcLevels.gcLevel[3].Dialog"
    const stateIndex = info.states.findIndex(
      (n) => n["State Name"] === stateName
    );
    let key = `[${stateIndex}].gcLevels.${gcLevel}`;
    // get gc levels array
    const gcLevels = _.get(info.states, key);
    const gcLevelStateIndex = gcLevels.findIndex(
      (n) => n["User Events"] === action
    );
    key += `.[${gcLevelStateIndex}].NextState`;

    _.set(info.states, key, nextState);

    // Add state to scene states.
    thunkApi.dispatch(scenePut({ id: scene.id, payload: info }));
  }
);

export default function reducer(state = initState, action) {
  switch (action.type) {
    case scenesGet.fulfilled.type:
      return { ...state, scenes: action.payload };
    case sceneGet.fulfilled.type:
      return { ...state, scene: action.payload };
    case sceneEditingGet.pending.type:
      return { ...state, editingSceneLoading: true };
    case sceneEditingGet.fulfilled.type:
      return {
        ...state,
        editingSceneLoading: false,
        editingScene: action.payload,
      };
    case sceneEditingNullOut.type:
      return { ...state, editingSceneLoading: false, editingScene: null };
    case sceneNullOut.type:
      return { ...state, editingScene: null, scene: null };
    case sceneCreate.fulfilled.type: {
      const newScene = action.payload;
      const scenes = [...state.scenes];
      scenes.push(newScene);
      return { ...state, scenes };
    }
    case scenePut.fulfilled.type: {
      const { info } = action.payload;
      // Update editing scene and maybe scene?
      const updateScene = cloneDeep(state.editingScene);
      updateScene.info = info;
      return { ...state, editingScene: updateScene };
    }
    case sceneDelete.fulfilled.type: {
      const id = action.meta.arg;
      const scenes = [...state.scenes].filter((n) => n.id !== id);
      return { ...state, scenes };
    }
  }
  return state;
}
