import { getSceneIdFromName } from "../../utils/utils";
import { toast } from "react-toastify";
import {
  faArrowLeft,
  faArrowRight,
  faCaretRight,
  faChartLine,
  faClock,
  faCommentDots,
  faHeart,
  faPlay,
  faRightFromBracket,
  faRoad,
  faStop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import dayjs from "dayjs";
import AppController from "../../utils/AppController";
import { sceneGet } from "../../utils/redux/features/scenes";
import A1Loader from "../A1Loader";
import { API } from "aws-amplify";
import { API_NAME } from "../../utils/constants";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// This also acts as a controller
const ReplayView = (props) => {
  const theme = useTheme();
  const { breadcrumbs, scene, createdAt, onClose, user } = props;
  const {
    createdAt: userCreatedAt,
    email,
    email_verified,
    id,
    lastActivity,
    sessions,
    updatedAt,
  } = user;
  const [activeStep, setActiveStep] = useState(0);
  const [sceneDetails, setSceneDetails] = useState(null);
  const dispatch = useDispatch();

  const { action, glucose, bgLevel, state, time } = breadcrumbs[activeStep];

  const fetchScene = async () => {
    try {
      const id = getSceneIdFromName(scene["Scene Name"]);
      const res = await API.get(API_NAME, `/scenes/${id}`);
      console.log(res.body);
      setSceneDetails(res.body.info);
    } catch (e) {
      console.log(e);
      toast.error("Error fetching scene details");
      onClose();
    }
  };

  useEffect(() => {
    fetchScene();
  }, []);

  if (!sceneDetails) {
    return <A1Loader text="Getting Scene Details" />;
  }

  const getStateDetailsFromCrumb = (crumb) => {
    const details = sceneDetails.states
      .find((n) => n["State Name"] === crumb.state)
      .gcLevels[bgLevel].find((n) => n["User Events"] === crumb.action);
    return details;
  };

  // Get Scene details for this action
  const stateDetails = getStateDetailsFromCrumb(breadcrumbs[activeStep]);
  if (!stateDetails) {
    toast.error(
      "stateDetails not found. This is likely caused by a Senario not having a END state set."
    );
    onClose();
    return;
  }

  const { dialog } = AppController.parseDialog(stateDetails.Dialog);
  const sterilizedDialog = AppController.sterilizeDialog(dialog);

  console.log(stateDetails);

  return (
    <Dialog fullWidth open onClose={onClose} disableEnforceFocus>
      {/* Top Row */}
      <div className="d-flex align-items-center justify-content-between w-100">
        <DialogTitle>Scenario Review</DialogTitle>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faUser} />
          <small className="m-2">{email}</small>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Typography variant="h5">{scene["Scene Title"]}</Typography>
        <Typography variant="small" className="m-2 mt-3">
          Completed {dayjs(createdAt).fromNow()}
        </Typography>
      </div>
      <DialogContent>
        <Card>
          <CardHeader title={`${activeStep + 1}. State - ${state}`} />

          <CardContent className="d-flex flex-column">
            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <FontAwesomeIcon icon={faRoad} />
              <Typography variant="body1">{`Action - ${action}`}</Typography>
            </div>
            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <FontAwesomeIcon icon={faClock} />
              <Typography variant="body1">{`Time - ${dayjs(time).format(
                "HH:mm a"
              )}`}</Typography>
            </div>
            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <FontAwesomeIcon icon={faHeart} />
              <Typography variant="body1">{`BG - ${glucose}`}</Typography>
            </div>
            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <FontAwesomeIcon icon={faChartLine} />
              <Typography variant="body1">{`BG Level - ${bgLevel}`}</Typography>
            </div>
            <Divider className="m-2" />
            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <FontAwesomeIcon icon={faCommentDots} />
              <Typography variant="body1">Dialog</Typography>
            </div>
            <Typography variant="body2" color="text.secondary" padding={2}>
              {sterilizedDialog}
            </Typography>
          </CardContent>
        </Card>
        <div className="d-flex flex-wrap">
          {breadcrumbs.map((crumb, key) => {
            const _stateDetails = getStateDetailsFromCrumb(crumb);
            const showCaret = parseInt(key) < breadcrumbs.length - 1;
            let { action } = crumb;
            if (crumb.action.toLowerCase().startsWith("on")) {
              action = action.substring(2);
            }
            return (
              <div className="d-flex align-items-center" key={key}>
                <Button
                  color={activeStep === key ? "primary" : "secondary"}
                  onClick={() => {
                    setActiveStep(parseInt(key));
                  }}
                >
                  {action}
                </Button>
                {showCaret && <FontAwesomeIcon icon={faCaretRight} />}
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <MobileStepper
          variant="progress"
          steps={breadcrumbs.length}
          position="static"
          activeStep={activeStep}
          sx={{ flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }}
              disabled={activeStep === breadcrumbs.length - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReplayView;
