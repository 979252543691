import React from "react";
import LoadingOverlay from "react-loading-overlay";

const A1Loader = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: "Monserrat,sans-serif",
        fontSize: "4em",
        fontWeight: "900",
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#001322",
      }}
    >
      <div style={{ width: "100%", height: "100%", marginTop: "5%" }}>
        <LoadingOverlay
          active={true}
          spinner
          text={text}
          spinnerSize="200px"
          background="#001322"
          color="#4a4a4a"
        />
      </div>
    </div>
  );
};

export default A1Loader;
