import { HostObject, anim } from "@amazon-sumerian-hosts/babylon";
import { Mesh, MeshBuilder, StandardMaterial, Texture } from "@babylonjs/core";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Axis, Vector3, Vector4 } from "@babylonjs/core/Maths/math";
import { Scene } from "@babylonjs/core/scene";
import AWS from "aws-sdk";
import CameraController from "./CameraController";
import DemoUtils from "./demo-utils";
import EventManager from "./EventManager";
import { store } from "./redux/configureStore";
import { uiAddImage, uiIsIOSModalOpen } from "./redux/features/ui";
import roomImg from "./room3.jpeg";
import { postMessage } from "./stubs";
import { is_iOS } from "./constants";

var HostAnimData = require("./HostAnimsData.json"); //(with path)
const ANIM_LAYER = "Base"; // "AnimationLayer";
const BASE_LAYER = "Base";

class ModelController {
  host = null;
  patient = null;
  scene = null;
  engine = null;
  finishedLoadingCallback = null;
  animQue = [];
  idleAnim = "";
  isPlayingAnim = false;
  constructor(callback) {
    this.finishedLoadingCallback = callback;
    this.init();
  }
  async init() {
    // ===== Configure the AWS SDK =====
    AWS.config.region = "us-east-2";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-2:6eabdac5-2f15-4c1e-9dbb-efd1727cf043",
    });

    window.HostObject = HostObject;

    const polly = new AWS.Polly();
    const presigner = new AWS.Polly.Presigner();
    // polly.describeVoices((e, f) => {
    //   console.log(e, f);
    // });
    await HostObject.initTextToSpeech(polly, presigner);

    window.polly = polly;

    this.scene = await this.createScene();

    // "Cristine", "Fiona", "Grace", "Maya", "Jay", "Luke", "Preston", "Wes"
    // Owner is a babylon mesh
    const hostName = "Fiona";
    this.host = await this.addHost(hostName);
    this.host.name = hostName;
    this.host.owner.position.x = 0.5;
    this.host.owner.position.y = -0.3;
    this.host.owner.rotate(Axis.Y, -0.3, "local");

    const patientName = "Wes";
    this.patient = await this.addHost(patientName);
    this.patient.name = patientName;
    this.patient.owner.position.x = -0.5;
    this.patient.owner.position.y = -0.3;
    this.patient.owner.rotate(Axis.Y, 0.3, "local");

    // add avatar
    // await this.addAvatar();

    // listen to custom message from ssml
    this.host.listenTo(
      this.host.TextToSpeechFeature.EVENTS.play,
      this.onStartSpeaking
    );
    this.host.listenTo(
      this.host.TextToSpeechFeature.EVENTS.stop,
      this.onStopSpeaking
    );
    this.host.listenTo(this.host.TextToSpeechFeature.EVENTS.ready, () => {
      this.host.isReady = true;
    });
    this.host.listenTo(
      this.host.TextToSpeechFeature.EVENTS.ssml,
      ({ mark }) => {
        const { value } = mark;
        this.onMark(value, true);
      }
    );
    this.patient.listenTo(
      this.patient.TextToSpeechFeature.EVENTS.play,
      this.onStartSpeaking
    );
    this.patient.listenTo(
      this.patient.TextToSpeechFeature.EVENTS.stop,
      this.onStopSpeaking
    );
    this.patient.listenTo(
      this.patient.TextToSpeechFeature.EVENTS.ready,
      () => {}
    );

    // this.patient.listenTo("AnimationFeature.onAnimationEnd", (e) => {
    //   console.log("Anim Ended!");
    // });

    // this.patient.listenTo("AnimationFeature.onResumeEvent", () => {
    //   console.log("onResumeEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onStopEvent", (e) => {
    //   console.log("onStopEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onNextEvent", () => {
    //   console.log("onNextEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onPauseEvent", (e) => {
    //   console.log("onPauseEvent", e);
    // });
    // this.patient.listenTo("AnimationFeature.onAddAnimationEvent", () => {
    //   console.log("onAddAnimationEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onAddLayerEvent", () => {
    //   console.log("onAddLayerEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onInterruptEvent", () => {
    //   console.log("onInterruptEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onPlayEvent", () => {
    //   console.log("onPlayEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onRemovedAnimationEvent", () => {
    //   console.log("onRemovedAnimationEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onRemoveLayerEvent", () => {
    //   console.log("onRemoveLayerEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onRenameAnimationEvent", () => {
    //   console.log("onRenameAnimationEvent");
    // });
    // this.patient.listenTo("AnimationFeature.onRenameLayerEvent", () => {
    //   console.log("onRenameLayerEvent");
    // });

    // this.patient.listenTo(
    //   this.patient.AnimationFeature.EVENTS.(something here)
    //   () => {}
    // );
    this.patient.listenTo(
      this.patient.TextToSpeechFeature.EVENTS.ssml,
      ({ mark }) => {
        const { value } = mark;
        this.onMark(value, false);
      }
    );

    this.scene.render();
    this.engine.runRenderLoop(() => this.scene.render());

    window.addEventListener("resize", () => this.engine.resize());

    window.camera = new CameraController(this.scene);

    this.finishedLoadingCallback();

    this.update();
    // setInterval(this.update.bind(this), 10);
  }

  onMark(markName, isHost) {
    // console.log("before split", markName);
    const cmd = markName.split(":");

    // console.log("boom", cmd);
    // before split image:url:https://images:unsplash:com/photo-1551782450-a2132b4ba21d
    // #image.url.https://images.unsplash.com/photo-1551782450-a2132b4ba21d
    // [
    //   "image",
    //   "url",
    //   "https",
    //   "//images",
    //   "unsplash",
    //   "com/photo-1551782450-a2132b4ba21d",
    // ];

    if (markName.search("image:url:") > -1) {
      console.log("TODO: update to take s3 ids instead of full urls.");
      const url = cmd.splice(2, 2).join(":");
      store.dispatch(uiAddImage(url));
    }

    if (markName.search("screen:") > -1) {
      const screen = cmd[1].toLowerCase();
      EventManager.fire("#screen." + screen);
    }

    // camera zoom / animations
    else if (markName.search("cam:") > -1) {
      switch (cmd[1].toLowerCase()) {
        case "host":
          window.camera.lookAtHost();
          break;
        case "avatar":
          window.camera.lookAtPatient();
          break;
        case "avatar-low":
          window.camera.lookAtPatientLow();
          break;
        case "wide":
          window.camera.wideView();
          break;
        default:
          window.camera.wideView();
          break;
      }
    }

    // Rotate avatar
    else if (markName.search("rotate:") > -1) {
      const isHost = cmd[1].toLowerCase() === "host";
      const entity =
        cmd[1].toLowerCase() === "host" ? this.host.owner : this.patient.owner;
      const degrees = parseInt(cmd[2]);
      const rads = degrees * (Math.PI / 180);

      // face forward
      if (rads === 0) {
        entity.rotation = isHost
          ? new Vector3(0, 2.7, 0)
          : new Vector3(0, 3.7, 0);
      }
      // face eachother
      else {
        entity.rotation = new Vector3(0, rads, 0);
      }
    } else if (markName.search("lookat:") > -1) {
      const subject = cmd[1];
      const key = "char:eyebrows";
      if (subject === "host") {
        // const mesh = this.host.owner
        //   .getChildMeshes(false)
        //   .filter((n) => n.id.indexOf(key) > -1)[0]
        //   .getPositionInCameraSpace(window.camera.camera);
        this.patient.PointOfInterestFeature.setTargetByName(key);
      } else if (subject === "avatar") {
        // const mesh = this.patient.owner
        //   .getChildMeshes(false)
        //   .filter((n) => n.id.indexOf(key) > -1)[0];
        this.host.PointOfInterestFeature.setTargetByName(key);
      } else if (subject === "cam") {
        this.patient.PointOfInterestFeature.setTarget(this.scene.activeCamera);
        this.host.PointOfInterestFeature.setTarget(this.scene.activeCamera);
      }
    }

    // do gestures (for host only)
    else if (markName.toLowerCase().indexOf("gesture:") > -1) {
      const gesture = markName.split(":")[1].toLowerCase();
      this.host.GestureFeature.playGesture("Gesture", gesture);
    }
    // avatar only gesture / amins
    else if (markName.toLowerCase().indexOf("anim:") > -1) {
      const anim = markName.split(":")[1].toLowerCase();
      // TODO: Add future support for host anims.
      if (anim.toLowerCase().startsWith("idle")) {
        console.log("setting idleAnim to: ", anim);
        this.idleAnim = anim;
        // if there's nothing in the anim que play idel now
        if (!this.animQue.length && this.idleAnim) {
          this.playIdelAnim();
        }
      } else {
        this.animQue.push(anim);
      }
    }
  }
  onStartSpeaking(e) {}
  onStopSpeaking(e) {
    // window.scenarioController.advanceSpeaking();
    // console.log("TODO - fix this");

    const context =
      window.modelController.host._features.TextToSpeechFeature._audioContext;
    // running or suspended

    if (is_iOS() && context.state === "suspended") {
      store.dispatch(uiIsIOSModalOpen(true));
    }

    setTimeout(() => {
      postMessage({
        type: "a1control",
        details: { action: "speech-finished" },
      });
    }, 1000);
  }

  // async addAvatar() {
  //   // const url = "character-assets/characters/adult_male/malcolm/Yelling.gltf";
  //   // const url = "character-assets/characters/adult_male/malcolm/0"; //Yelling.gltf";
  //   // const url = "character-assets/characters/adult_male/malcolm/Yelling.glb"; //Yelling.gltf";
  //   const url = "character-assets/characters/adult_male/malcolm/Malcolm.glb"; //Yelling.gltf";

  //   const model = await SceneLoader.AppendAsync(
  //     url,
  //     undefined,
  //     this.scene,
  //     function (event) {
  //       console.log(event);
  //       // Compute the percentage for each stage unless the length is not computable.
  //       // The lengthComputable is often false when serving content that is gzipped.
  //       const percentage = event.lengthComputable
  //         ? " " + Math.floor((event.loaded / event.total) * 100) + "%"
  //         : "";
  //       console.log("percent: ", percentage);

  //       // Check if an LOD is loading yet.
  //       // if (lodNext === null) {
  //       //   // Ignore GLB header progress.
  //       //   if (event.total === 20) return;

  //       //   // Show that the glTF is downloading.
  //       //   bottomLine.text = "Loading glTF..." + percentage;
  //       // } else {
  //       //   // Show that the LOD is downloading.
  //       //   bottomLine.text =
  //       //     "Loading '" + lodNames[lodNext] + "' LOD..." + percentage;
  //       // }
  //     },
  //     ".glb"
  //   ); //.then(function (scene) {
  //   console.log(model);
  //   // Create a default camera that can view the whole model.
  //   // scene.createDefaultCamera(true, true, true);
  //   // // Adjust the camera to face the front of the model.
  //   // scene.activeCamera.alpha += Math.PI;
  //   // // Adjust the camera to view the model from the top slightly.
  //   // scene.activeCamera.beta -= Math.PI / 15;
  //   // // Zoom in on the model.
  //   // scene.activeCamera.radius *= 0.5;
  //   // // Auto rotate the camera.
  //   // scene.activeCamera.useAutoRotationBehavior = true;
  //   // });
  //   // This sceneloader is for loading gltf models it constructor takes : location, name, scene
  //   //  var character = BABYLON.SceneLoader.ImportMesh(
  //   //   "",
  //   //   "https://ak228212.github.io/babylonJs/assets/undercover_cop_-_animated/",
  //   //   "scene.gltf",
  //   //   scene,
  //   // await SceneLoader.AppendAsync(
  //   //   "character-assets/characters/adult_male/malcolm/",
  //   //   "Yelling.gltf",
  //   //   this.scene,
  //   //   (e) => {
  //   //     console.log(e);
  //   //   }
  //   // );
  //   //   this.scene,
  //   //   (e) => {
  //   //     console.log(e);
  //   //   }
  //   // );
  //   // await SceneLoader.AppendAsync(
  //   //   "./character-assets/characters/adult_male/malcolm/",
  //   //   // "MiConv.com__Exercise-Burpee.gltf",
  //   //   // "malcolm.gltf",
  //   //   "Yelling.gltf",
  //   //   this.scene
  //   // (progress) => {
  //   //   console.log(progress);
  //   // }
  //   // ".gltf"
  //   // );
  // }

  async addHost(characterId) {
    // Edit the characterId if you would like to use one of
    // the other pre-built host characters. Available character IDs are:
    // "Cristine", "Fiona", "Grace", "Maya", "Jay", "Luke", "Preston", "Wes"
    let voice = characterId;
    if (characterId === "Wes") {
      voice = "Matthew"; //"Miguel";
    }
    const pollyConfig = {
      pollyVoice: voice,
      pollyEngine: "neural",
    };
    const characterConfig = HostObject.getCharacterConfig(
      "./character-assets",
      characterId
    );

    // console.log("===CHAR CONFIG===");
    // console.log(characterConfig);

    const model = await HostObject.createHost(
      this.scene,
      characterConfig,
      pollyConfig
    );

    // Tell the host to always look at the camera.
    model.PointOfInterestFeature.setTarget(this.scene.activeCamera);

    // Add layer for custom animations
    // const af = model.AnimationFeature;
    // console.log(af);
    model.AnimationFeature.addLayer(
      "AnimationLayer",
      {
        // transitionTime: 0.5,
        // blendMode: anim.LayerBlendModes.Additive,
      },
      0
    );
    // setLayerWeight(name, weight, seconds, easingFn) {
    model.AnimationFeature.setLayerWeight("AnimationLayer", 1, 0.4);

    return model;
  }

  async createScene() {
    // Create an empty scene. Note: Sumerian Hosts work with both
    // right-hand or left-hand coordinate system for babylon scene
    const canvas = document.getElementById("renderCanvas");
    // make sure canvas exists

    // Scene
    this.engine = new Engine(canvas, true); //, undefined, true);
    const scene = new Scene();
    // scene.useRightHandedSystem = true;

    DemoUtils.setupSceneEnvironment(scene);

    // Enable shadows.
    // scene.meshes.forEach((mesh) => {
    //   shadowGenerator.addShadowCaster(mesh);
    // });

    // const background = new Layer(
    //   "background",
    //   roomImg,
    //   // "images/room1.jpeg",
    //   scene,
    //   true
    // );
    // background.render();
    // console.log(background);

    // var backgroundMaterial = new BackgroundMaterial(
    //   "backgroundMaterial",
    //   scene
    // );
    // backgroundMaterial.diffuseTexture = new Texture(roomImg, scene);
    // backgroundMaterial.diffuseTexture.hasAlpha = true;
    // backgroundMaterial.opacityFresnel = false;
    // backgroundMaterial.shadowLevel = 0.4;

    const mat = new StandardMaterial("");
    mat.diffuseTexture = new Texture(roomImg);
    const plane = MeshBuilder.CreatePlane(
      "plane",
      {
        size: 4,
        backUVs: new Vector4(0, 0, 1, 1),
        sideOrientation: Mesh.BACKSIDE,
        width: 7,
      },
      scene
    );
    plane.material = mat;
    plane.position.y = 1;
    plane.position.z = -1;
    window.plane = plane;

    return scene;
  }

  stopAllSpeech() {
    this.patient.TextToSpeechFeature.stop();
    this.host.TextToSpeechFeature.stop();
  }

  stopSpeech(isHost = true) {
    isHost
      ? this.host.TextToSpeechFeature.stop()
      : this.patient.TextToSpeechFeature.stop();
  }

  say(message, isHost = true) {
    // Attempt to play audio on iOS devices without user interaction
    // This approach uses a silent audio loop to keep the audio context in an "unlocked" state
    // if (is_iOS()) {
    //   let context;
    //   if (true) {
    //     //!context.addedHost) {
    //     alert(context.state);
    //     context = this.host._features.TextToSpeechFeature._audioContext;
    //     context.addedHost = true;
    //     console.log(context);
    //     const buffer = context.createBuffer(1, 1, 22050);
    //     const source = context.createBufferSource();
    //     source.buffer = buffer;
    //     source.connect(context.destination);
    //     source.loop = true;
    //     source.start(0);
    //   }
    //   if (true) {
    //     //!context.addedPatient) {
    //     alert(context.state);
    //     context = this.patient._features.TextToSpeechFeature._audioContext;
    //     context.addedPatient = true;
    //     console.log(context);
    //     const buffer = context.createBuffer(1, 1, 22050);
    //     const source = context.createBufferSource();
    //     source.buffer = buffer;
    //     source.connect(context.destination);
    //     source.loop = true;
    //     source.start(0);
    //   }

    //   // if (context.state === "suspended") {
    //   //   const unlock = () => {
    //   //     context.resume().then(() => {
    //   //       document.removeEventListener("touchstart", unlock);
    //   //       document.removeEventListener("touchend", unlock);
    //   //     });
    //   //   };

    //   //   document.addEventListener("touchstart", unlock, false);
    //   //   document.addEventListener("touchend", unlock, false);
    //   // }
    // }

    // if for some reason the dialog message is empty we still need to advance the engine by calling onStopSpeaking()
    if (!message.trim().length) {
      this.onStopSpeaking();
      return this;
    }

    // check for IOS and pause execution for user interaction event, otherwise audio won't play (eyeroll)
    if (is_iOS()) {
      window.__modelControllerParams = {
        isHost,
        message,
        host: this.host,
        patient: this.patient,
      };
      // store.dispatch(uiIsIOSModalOpen(true));
      // return;
    }

    try {
      if (!isHost) {
        this.patient.TextToSpeechFeature.play(message);
        const context =
          this.patient._features.TextToSpeechFeature._audioContext;
        // running or suspended
        // console.log("context patient", context.state);
        // console.log("patient", context.state);
        // console.log(context);
        // const buffer = context.createBuffer(1, 1, 22050);
        // const source = context.createBufferSource();
        // source.buffer = buffer;
        // source.connect(context.destination);
        // source.loop = true;
        // source.start(0);
      } else {
        this.host.TextToSpeechFeature.play(message);
        const context = this.host._features.TextToSpeechFeature._audioContext;
        // console.log("context host state", context.state);
        // console.log("host", context.state);
        // console.log(context);
        // const buffer = context.createBuffer(1, 1, 22050);
        // const source = context.createBufferSource();
        // source.buffer = buffer;
        // source.connect(context.destination);
        // source.loop = true;
        // source.start(0);
      }
    } catch (err) {
      console.error(err);
    }
    return this;
  }

  async gesture(model, kind, holdTime = 1.5) {
    const params = {
      holdTime, // how long the gesture should last
      minimumInterval: 0, // how soon another gesture can be triggered
    };
    await model.GestureFeature.playGesture("Gesture", kind, params);
  }

  // AnimationFeature docs - https://aws-samples.github.io/amazon-sumerian-hosts/AnimationFeature.html
  async overrideIdle(glbName /*, isHost = false*/) {
    const assetsPath = "./character-assets";
    const characterType = "adult_male";
    const url = `${assetsPath}/animations/${characterType}/${glbName}.glb`;
    let animChar = this.patient;
    // if (isHost)
    // {
    //   animChar = this.host;
    // }

    const childMeshes = animChar.owner.getDescendants();

    const { clipGroupId, clips } = await HostObject.loadAnimation(
      this.scene,
      childMeshes,
      url,
      glbName
    );
    const clip = clips[0];
    const clipinfo = { clip: clip };

    let anims = animChar.AnimationFeature.getAnimations("Base");
    if (!anims.includes(glbName)) {
      let rc = animChar.AnimationFeature.addAnimation(
        "Base",
        clip.name,
        anim.AnimationTypes.single,
        clipinfo
      );
      let clipname = rc;
      rc = animChar.AnimationFeature.renameAnimation("Base", clipname, glbName);
    }
    // console.log("SET Idle to " + glbName);
    animChar.AnimationFeature.playAnimation("Base", glbName);
  }

  async clearAnimCache(glbName, loopcnt = 1 /*, isHost = false*/) {
    // const ANIM_LAYER = "AnimationLayer";
    // let animChar = this.patient;
    // if (isHost)
    // {
    //   animChar = this.host;
    // }
    // DELETE cached animations
    // let anims = animChar.AnimationFeature.getAnimations(ANIM_LAYER);
    // console.log(anims);
    // while (anims.length) {
    //   let animname = anims.pop();
    //   console.log(animname);
    //   animChar.AnimationFeature.stopAnimation(ANIM_LAYER,animname);
    //   // animChar.AnimationFeature.removeAnimation(ANIM_LAYER,animname);
    // }
  }

  async playAnim(glbName, loopcnt = 1 /*, isHost = false*/) {
    // const ANIM_LAYER = "Base"; // "AnimationLayer";  <-  Run these in the base layer unless going to impliiment BLENDING

    // CREATE A CALLBACK FUNCTION ON THE END OF ANIM
    // var eventAnimDone = new BABYLON.AnimationEvent(
    //   22,
    //   function () {
    //     console.log("Callback end of anim!");
    //   },
    //   true
    // );

    // make clips
    const assetsPath = "./character-assets";
    const characterType = "adult_male";
    const url = `${assetsPath}/animations/${characterType}/${glbName}.glb`;
    let model = this.patient;

    // if (isHost)
    // {
    //   model = this.host;
    // }

    // GET THE NUMBER OF FRAMES FOR THIS ANIMATION
    let numFrames = 0;
    const index = HostAnimData.findIndex((an) => an.name === glbName);
    if (index >= 0) {
      numFrames = HostAnimData[index].frames;
    }
    // console.log("PLAY: ", glbName, " Anim ( ", numFrames, " frames)");

    // Get the current idle state for after finish
    let a = model.AnimationFeature.getCurrentAnimation(BASE_LAYER);
    // console.log(a);

    const childMeshes = model.owner.getDescendants();
    const { clipGroupId, clips } = await HostObject.loadAnimation(
      this.scene,
      childMeshes,
      url,
      glbName
    );

    // Load the file if it is not already been used
    let anims = model.AnimationFeature.getAnimations(ANIM_LAYER);
    const clip = clips[0];
    if (!anims.includes(glbName)) {
      // console.log(clips);

      // const af = model.AnimationFeature;
      // console.log(af);

      const clipinfo = { clip: clip };
      if (loopcnt) {
        clipinfo.loopCount = loopcnt;
      }

      // let rc = model.AnimationFeature.addAnimation(
      //   ANIM_LAYER,
      //   clip.name,
      //   anim.AnimationTypes.single,
      //   clipinfo
      // );

      // AnimationGroup.MakeAnimationAdditive(clip);
      await model.AnimationFeature.addAnimation(
        ANIM_LAYER,
        clip.name,
        anim.AnimationTypes.single,
        {
          clip,
          loopCount: loopcnt,
        }
      );
      // model.AnimationFeature.playAnimation(ANIM_LAYER, clip.name);

      // On end go back to the current idle
      //model.AnimationFeature.addEventListener()
      // model.AnimationFeature.addAnimation(
      model.AnimationFeature.renameAnimation(ANIM_LAYER, clip.name, glbName);
    }

    await model.AnimationFeature.playAnimation(ANIM_LAYER, glbName);
    this.isPlayingAnim = false;

    if (!this.animQue.length && this.idleAnim) {
      this.playIdelAnim();
    }

    // 3 parameters to create an event:
    // - The frame at which the event will be triggered
    // - The action to execute
    // - A boolean if the event should execute only once (false by default)
    // Attach your event to your animation
    // const evt = new AnimationEvent(
    //   26,
    //   () => {
    //     console.log("done anim!");
    //   },
    //   true
    // );
    // _anim.addEvent(evt);
  }

  async playIdelAnim() {
    if (!this.idleAnim) {
      console.warn("this.idelAnim not set, returning");
      return;
    }
    // make clips
    const model = this.patient;
    const assetsPath = "./character-assets";
    const characterType = "adult_male";
    const url = `${assetsPath}/animations/${characterType}/${this.idleAnim}.glb`;

    const childMeshes = model.owner.getDescendants();
    const { clipGroupId, clips } = await HostObject.loadAnimation(
      this.scene,
      childMeshes,
      url,
      this.idleAnim
    );

    // Load the file if it is not already been used
    let anims = model.AnimationFeature.getAnimations(BASE_LAYER);
    const clip = clips[0];
    if (!anims.includes(this.idleAnim)) {
      await model.AnimationFeature.addAnimation(
        BASE_LAYER,
        clip.name,
        anim.AnimationTypes.single,
        {
          clip,
          // loopCount: 0, // Play forever
        }
      );
      model.AnimationFeature.renameAnimation(
        BASE_LAYER,
        clip.name,
        this.idleAnim
      );
    }

    console.log("Playing Idel Anim: ", this.idleAnim);
    await model.AnimationFeature.playAnimation(BASE_LAYER, this.idleAnim);
  }

  update() {
    // const isPlayingAnim =
    //   this.patient.AnimationFeature.getCurrentAnimation(ANIM_LAYER);
    if (!this.isPlayingAnim && this.animQue.length) {
      console.log("animQue", this.animQue);
      this.isPlayingAnim = true;
      this.playAnim(this.animQue.shift());
    }

    // const isPlayingIdle =
    //   this.patient.AnimationFeature.getCurrentAnimation(BASE_LAYER);
    // console.log("isPlayingIdle", isPlayingIdle);

    window.requestAnimationFrame(this.update.bind(this));
  }
}
export default ModelController;
