import { store } from "../utils/redux/configureStore";

export const getSceneFromName = (sceneName) => {
  const state = store.getState();
  const scenes = state.scenes;
  // const scenes = store.getState().scenes.scenes;
  // Find scene where scene.info['Scene Name'] === scene.data['Scene Name']
  const scene = scenes.scenes.find((n) => n.info["Scene Name"] === sceneName);
  return scene;
};

export const getSceneIdFromName = (sceneName) => {
  const scene = getSceneFromName(sceneName);
  if (scene && scene.id) {
    return scene.id;
  }
  return null;
};
